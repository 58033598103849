<template>
	<v-dialog v-model="dialog" width="800" eager>
		<template v-slot:activator="{props}">
			<v-btn class="mt-2" block size="small" color="success" v-bind="props">
				<v-icon class="mr-2">mdi-pencil</v-icon>
				<span>{{
					includedOrganisations.length ? "Edit" : "Add organisations"
				}}</span>
			</v-btn>
		</template>
		<v-card flat>
			<v-card-title>Settings</v-card-title>
			<v-card-text>
				<p>Include data for the following organisations in the table</p>
				<panel-selection
					v-model="dashboard.includedOrganisations"
					:options="allOrganisations"></panel-selection>
			</v-card-text>
			<v-card-actions>
				<v-spacer></v-spacer>
				<v-btn color="success" @click="dialog = false"> Done </v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>
<script setup>
	import {useDashboardStore} from "@/stores/modules/dashboard";
	import {useOrganisationsStore} from "@/stores/modules/organisations";
	import {computed, watch, ref} from "vue";

	import PanelSelection from "@c/data-query-wizard/PanelSelection";

	const dashboard = useDashboardStore();
	const organisationsStore = useOrganisationsStore();

	const dialog = ref(false);
	const allOrganisations = computed(() =>
		organisationsStore.collection.map((o) => ({title: o.name, value: o.id}))
	);
	const includedOrganisations = computed(() => dashboard.includedOrganisations);

	watch(includedOrganisations, (newValue, oldValue) => {
		newValue
			.filter((o) => !oldValue.includes(o))
			.forEach((v) => {
				const org = organisationsStore.keyed[v.value];
				organisationsStore.includeOrganisation(org, true);
			});
		if( oldValue.length ){

			oldValue
				.filter((o) => !newValue.includes(o))
				.forEach((v) => {
					organisationsStore.removeOrganisation(v.value);
				});
		}
	});

</script>
