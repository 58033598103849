<template>
    <v-card class="mt-2" flat :title="title">
        <v-card-text>
            <p class="mb-6">
                <i v-if="multiple">Select <u>all</u> that apply</i>
                <i v-else>Select <u>one</u></i>
            </p>
            <v-item-group :model-value="modelValue" @update:model-value="v => $emit('update:model-value', v)" :multiple="multiple">
                <v-item  v-for="option in options" :key="option.value" :value="option.value" v-slot="{ isSelected, toggle }">
                    <v-card
                    :color="isSelected ? 'success' : option.color"
                    class="d-inline-block align-center  mr-2 mb-2 pa-3"
                    width="180"
                    height="160"
                    dark
                    @click="toggle"
                    :disabled="option.disabled"
                    >
                    <div
                        class="text-center justify-center"
                    >
                    <div class="pt-2 pb-3" v-if="option.icon">
                        <v-icon size="x-large">{{ option.icon }}</v-icon>
                    </div>
                    <div :class="{'pt-7': !option.icon}">{{option.title}}</div>
                    </div>
                    <div class="text-center mt-4" v-if="option.subtitle" style="font-size: 0.75em">
                        <i>{{ option.subtitle }}</i>    
                    </div>
                    </v-card>
                </v-item>
        </v-item-group>

        </v-card-text> 
    </v-card>
</template>

<script setup>

import {ref, defineProps, computed, watch } from "vue";
const props = defineProps({
    allowBack: Boolean,
    title: String, 
    multiple: Boolean, 
    options: Array, 
    modelValue: [Array, String, Number]
})

const disabled = computed( () => {
    if( props.multiple ){
        return !props.modelValue.length;    
    }
    else{
        return !props.modelValue;
    }
})

const emit = defineEmits(["update:disabled", "update:model-value"])

watch( disabled, v => {
    emit('update:disabled', v)
}, {immediate: true});

</script>