<template>
<v-card>
    <v-toolbar flat color="transparent">
        <span class="text-h5 ml-5 text-success">
            <v-icon size="small">mdi-auto-fix</v-icon>
            New! Data Assistant
        </span>
        <v-spacer></v-spacer>
        <v-btn text flat @click="$emit('close')">
            Close
            <v-icon>mdi-close</v-icon>
        </v-btn>
    </v-toolbar>
    <v-card-text>
        <v-alert color="lightest">
            The data assistant can help you access some of the most common data views and formats quickly and easily!
        </v-alert>             
        <v-carousel
        v-model="stage"
        height="100%"
        hide-delimiters
        :show-arrows="false"
        
        >
            <v-carousel-item
            v-for="(question, i) in questions"
            :key="i"
            :value="i"
            >
            <question-inner class="mt-3cx "
                v-bind="question" 
                @update:disabled="v => disabled = v"
                v-model="responses[question.model]" >
                
            </question-inner>
            </v-carousel-item>
        </v-carousel>   
    </v-card-text>
    <v-card-actions>
        <v-btn :disabled="stage == 0" @click="() => stage = stage - 1">Back</v-btn>
        <v-btn @click="reset" text>Reset</v-btn>
        <v-spacer></v-spacer>
        <v-btn v-if="readyToSubmit" @click="submit" :loading="loading ? true : false">Submit</v-btn>
        <v-btn v-else :disabled="disabled" @click="next">Next</v-btn>
    </v-card-actions>
</v-card>
</template>

<script setup>
    
    import {ref, defineEmits, computed, watch } from "vue";
    import { useRouter } from 'vue-router'
    import QuestionInner from "./QuestionInner.vue";
    import { useTimeSeriesStore } from "@/stores/modules/timeSeries";
    import { useDashboardStore } from "@/stores/modules/dashboard";
    import { useOrganisationsStore } from "@/stores/modules/organisations";
    import { useAuthStore } from "@/stores/modules/auth";
    import { useNationalAverageStore } from "@/stores/modules/nationalAverages";
    import { useColumnsStore } from "@/stores/modules/columns";
    import TimeSeriesView from "@/views/TimeSeriesView.vue";
    import { findLast } from "lodash";

    const $emit = defineEmits(["close"]);

	const organisationsStore = useOrganisationsStore();
    const dashboardStore = useDashboardStore();
    const timeSeriesStore = useTimeSeriesStore();
    const authStore = useAuthStore();
    const nationalAveragesStore = useNationalAverageStore();
    const columnsStore = useColumnsStore();


    const router = useRouter();

	const defaultOrganisation = computed( () => {
        if( authStore.organisations[0] ){
            return organisationsStore.keyed[authStore.organisations[0]];
        }
		if( organisationsStore.collection[0] ){
			return organisationsStore.collection[0];
		}
		return null;
	})

    const autoClick = ref(null);
    const timeSeries = ref(null);
    const loading = computed(() => dashboardStore.loading);

    const stage = ref(0);
    const disabled = ref(false);
    const initialResponses = {
        A: null,
        B: [],
        C: 1,
        D: null,
        E: "table"
    }
    const responses = ref({...initialResponses});

    const initialQuestions = [
        {
            title: "What do you want to do?", 
            model: "A",
            subtitle: "Please select an option below. Don’t see what you need? Try using a custom query or contact us via the live chat for assistance.",
            multiple: false,
            options: [
                {title: "I want to look at an overview of my data", value: 1, color: "primary", icon: "mdi-chart-bar" },
                {title: "I want compare my organisation against a national average", value: 2, color: "primary", icon: "mdi-scale-unbalanced" },
                {title: "I want to see digital maturity information for my healthcare and social care system", value: 3, icon: "mdi-dots-circle", color: "primary" }, 
                {title: "I want to map my organisation's digital maturity along a timeline", value: 4, color: "primary", icon: "mdi-chart-timeline-variant-shimmer" }, 
                {title: "I want to download my organisation's digital maturity data", value: 5, color: "primary", icon: "mdi-download" }, 
                {title: "I want to generate charts for a PowerPoint presentation", value: 6, color: "primary", icon: "mdi-presentation" }, 
                {title: "None of those / I want to create my own query", value: 7, color: "secondary" }, 
                {title: "Focus on SaMD", value: 8, color: "accent", subtitle: "Show me data about this specialist subject for my organisation" }, 
                {title: "Focus on Integrated Care", value: 9, color: "accent",  subtitle: "Show me data about this specialist subject for my organisation" }, 
            ]
        },
        {
            title: "Which of the following do you want to include?", 
            model: "B",
            subtitle: "Select all assessment periods you want to include, then click NEXT when you’re done.",
            multiple: true,
            options: [
                {title: "2019 (coming soon)", value: 1, color: "primary", disabled: true  },
                {title: "2023", value: 2023, color: "primary", subtitle: "Initial assessment baseline, data collection 03/2023 - 11/2023" },
                {title: "Latest Available", value: 2024, color: "primary", subtitle: "Automatically includes the latest assessments available" }, 
            ]
        },
        {
            title: "Which data collection do you want to include?", 
            model: "C",
            subtitle: "Select the data collection you’d like to look at. For organisations’ responses to the Digital Maturity Assessment select ‘Main Assessment’. To view responses to the assessment’s Staff Survey component, select ‘Staff Survey’.",
            multiple: false,
            options: [
                {title: "Main Assessment", value: 1, color: "primary"  },
                {title: "Staff Survey (coming soon)", value: 2, color: "primary", disabled: true },
            ]
        },
        {
            title: "Choose your level of detail.", 
            model: "D",
            subtitle: "You can choose the level of detail you’d like to see on this page. The Digital Maturity Assessment encompasses more than 400 questions grouped into 20 subject-specific sections. Each section belongs to one of three overarching themes. ",
            multiple: false,
            options: [
                {title: "Data By Theme", value: "theme", color: "primary"  },
                {title: "Data By Section", value: "section", color: "primary" },
                {title: "Data By Question", value: "question", color: "primary" },
            ]
        },                  
        {
            title: "How would you like to see your data?", 
            model: "E",
            subtitle: "You can view the data you’ve selected in different ways. A bar chart represents the information in visual form, while a table provides the data in the form of with numbers. Pick the most suitable option for you below; don’t worry, you’ll be able to change this later. ",
            multiple: false,
            options: [
                {title: "Bar Chart", value: "viz", color: "primary", icon: "mdi-chart-bar" },
                {title: "Table", value: "table", color: "primary", icon: "mdi-table" }
            ]
        }
    ];

    let questions = ref([...initialQuestions]);
        
    let includeNationalAverage = ref(false);
            
    const displayColumns = () => {
        let columns = _.sortBy(columnsStore.data, "updated_at");
        // hide columns
        columns.forEach( col => {
            col.display = false;
        })
        // display section, theme, question
        let a = columns.find( col => col.value == responses.value.D  );
        a.display = true;

        // display the final in each year for each org;
        organisationsStore.collection.forEach( org => {
            responses.value.B.forEach( year => {
                let b  = columns.findLast( col => col.organisation == org.id && col.updated_at.getFullYear() == year && col.is_score );
                if( b ){
                    b.display = true; 
                }
            })
        });

    }

    const reset = ( ) => {
        responses.value = {...initialResponses};
        stage.value = 0;
        questions.value = [...initialQuestions];
    }
    const actions = {
        A: (response) => {
            switch (response) {
                case 1: 
                    addDefaultOrganisation();
                    break;
                case 2: 
                    addDefaultOrganisation();
                    includeNationalAverage.value = true;
                    break;
                    case 3:
                    addAllOrganisations();
                    break;
                case 4:
                    timeSeries.value = true;
                    timeSeriesStore.organisation = defaultOrganisation.value.id;
                    questions.value[3].options[2].disabled = true;
                    questions.value = questions.value.filter(q => q.model != "B");
                    questions.value.pop();
                    break;
                case 5: 
                    addDefaultOrganisation();
                    responses.value.E = "table";
                    autoClick.value = "download";
                    questions.value.pop()
                    break;
                case 6: 
                    addDefaultOrganisation();
                    dashboardStore.tab = "viz";
                    autoClick.value = "download";
                    responses.value.E = "viz";
                    questions.value[3].options[2].disabled = true;
                    questions.value.pop()
                    break;
                case 8:
                    addDefaultOrganisation();
                    break;
                case 9:
                    addDefaultOrganisation();
                    break;
                default:
                    break;
            }
        },
        B: (response) => {
            if( includeNationalAverage.value ){
                nationalAveragesStore.fetchCurrent([], response, false)
            }
        },
        D: (response) => {
            if( timeSeries.value ){
                timeSeriesStore.aggregateBy = response;
            }
        }
    }


    const goToQuery = () => {
        $emit("close");
        addDefaultOrganisation();
        responses.value.D = "section";
        autoClick.value = "query";
        readyToSubmit.value = true;
    }

    const samd = {
        "question": [
            "7gx9KBHayV0jSpsMrywD",
            "RndMQoeL0QoBxiA5aGob",
            "9nvgjvzJXKzVrPovp1ev",
            "ZGaHRdHIzAzj9ZQQAf9r",
            "vkB3MhhS6wkvPHRzQkzY",
            "Om6uPCTtenAwInQduD2O", 
            "xYaDZbkW6kquVKxAKesI"
        ]
    }
    const integration = {
        "question": [
            "FI6kU1gYJjkymsZOdLn0",
            "p8BzLe4QQ9KySWAb4Qy5",
            "NoIRLn9Vapnmi3igvXrS",
            "NUYqIxetaLCztlLbzVax",
            "Lia8m3HBQF97Wt3f72Wz",
            "Nnx6mgjRdSfYgaNktuCq",
            "8l74PK3e3Y7PFvgfHCm3",
            "JyubBbhbOjvtKlassuUS",
            "fUU21sX23CG6dy1KbtSS",
            "cMe0n0vkkYx79eEpXGKx",
            "jtiaCtuNUS9pRaHHfunK",
            "LJMBOrR8tNFEAjLDLyCW",
            "6hsOfApibUXThiO1cMoI",
            "YxNhhOZribocHyml2ViR",
            "G8BWyzHH98iaRWFTEq4P",
            "02ijb3oqdbM82NMKmpBe",
            "HrAx3vf1bzKewXwMEBZ2",
            "WNMTXWUfT7Ho2LAH2FnU",
            "2Frd7qaAu8gW7k0lVXZB"
        ]
    }

    const focusOn = (filter) => {
        responses.value.D = "question";
        responses.value.B = [2024];
        addDefaultOrganisation();
        dashboardStore.setFilters(filter);
        readyToSubmit.value = true;
    }

    const first = computed( () => responses.value.A)
    const readyToSubmit = ref(false)
    watch( first, (v) => {
        if( 7 == v ){
            goToQuery();
        }
        if( 8 == v ){
            focusOn(samd);
        }
        if( 9 == v ){
            focusOn(integration);
        }
    }, {deep: true});

    const goToTimeSeries = () => {
        router.push("/time-series")
    }

    const addDefaultOrganisation = () => {
		organisationsStore.includeOrganisation(defaultOrganisation.value);
    }

    const addAllOrganisations = async() => {
        await organisationsStore.includeOrganisations(organisationsStore.collection);
    }

    const next = () => {
        let question = questions.value[stage.value]; 
        let response = responses.value[question.model];
        let action = actions[question.model];
        if( action ){
            action(response);
        }
        stage.value++; 
    }


    watch( stage, v => {
        if( v == (questions.value.length - 1) ){
            readyToSubmit.value = true;
        }
    })

    const submit = () => {
        displayColumns();
        if( timeSeries.value ){
            goToTimeSeries();
        }   
        dashboardStore.tab = responses.value.E;
        dashboardStore.autoClick = autoClick.value;
        $emit( "close" )
    }
</script>
