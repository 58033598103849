<template>
	<div>
		<v-dialog v-model="dialog" width="800" eager>
			<template v-slot:activator="{props}">
				<v-btn class="mt-2" block color="grey" v-bind="props">
					<v-icon class="mr-3">mdi-filter-variant</v-icon>
					<span style="font-size: 0.7em">Custom Query Builder</span>
				</v-btn>
			</template>
			<v-card title="Custom Query Builder">
				<v-card-text>
					<v-window v-model="window">
						<v-window-item v-for="n in 4" :key="n">
							<v-card>
								<select-series v-if="n == 1" v-model="series" />
								<select-labels v-if="n == 2" v-model="label" />
								<select-services v-if="n == 3" v-model="services" />
								<select-rows
									template
									v-if="n == 4 && label"
									v-model="rows"
									:prop="label.value"></select-rows>
							</v-card>
						</v-window-item>
					</v-window>
				</v-card-text>
				<v-card-actions>
					<v-btn @click="dialog = false">Cancel</v-btn>
					<v-spacer></v-spacer>
					<v-btn color="success" @click="window--" v-if="window > 0">
						Back
					</v-btn>
					<v-btn color="success" @click="window++" v-if="window < 3">
						Next
					</v-btn>
					<v-btn color="success" @click="complete" v-if="window == 3">
						Finish
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</div>
</template>

<script setup>
	import {ref, computed, watch} from "vue";
	import SelectSeries from "@/components/data-query-wizard/SelectSeries.vue";
	import SelectLabels from "@/components/data-query-wizard/SelectLabels.vue";
	import SelectServices from "@/components/data-query-wizard/SelectServices.vue";
	import SelectRows from "@/components/data-query-wizard/SelectRows.vue";
	import {useDashboardStore} from "@/stores/modules/dashboard";
	import {useColumnsStore} from "@/stores/modules/columns";
	const dashboard = useDashboardStore();
	const columnsStore = useColumnsStore();
	let dialog = ref(false);
	let window = ref(0);

	const series = ref([]);
	const label = ref(null);
	const rows = ref([]);
	const services = ref([]);

	const filter = computed(() => {
		return {
			service: services.value.map((item) => item.value),
			[label.value.value]: rows.value.map((item) => item.value)
		};
	});

	const complete = () => {
		dashboard.setFilters(filter.value);
		let displaySeries = series.value.map((item) => item.value);
		columnsStore.data.forEach((col) => {
			col.display =
				displaySeries.includes(col.value) || label.value.value == col.value;
		});
		dialog.value = false;
		window.value = 0;
		label.value = null;
		rows.value = [];
		series.value = [];
	};

	const autoClick = computed( () => dashboard.autoClick );
	watch(autoClick, (value) => {
		if( value == "query" ){
			dialog.value = true;
		}
	}, {immediate: true})
</script>
