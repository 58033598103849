import _ from "lodash";
import {defineStore} from "pinia";
import {computed, ref, watch} from "vue";
import {useConfigStore} from "@/stores/modules/config";
import {useThemesStore} from "./themes";
import {useQuestionsStore} from "./questions";
import {useSectionsStore} from "./sections.js";
import {useRowsStore} from "./rows";
import {useColumnsStore} from "./columns";
import {useNationalAverageStore} from "./nationalAverages.js";
export const useDashboardStore = defineStore("dashboard", () => {
	const sectionsStore = useSectionsStore();
	const columnsStore = useColumnsStore();
	const nationalAverageStore = useNationalAverageStore();
	const rowsStore = useRowsStore();
	const defaultTitle = "Digital Maturity Assessment";
	const title = ref(defaultTitle);
	const loading = ref(0);
	const tab = ref("table");
	const years = ref([])
	const introduction = ref("");
	const chartData = ref(null);
	const selection = ref([]);
	const includedOrganisations = ref([]);
	const selected = ref([]);

	const themes = computed(() => {
		const coll = sectionsStore.collection || [];
		let values = coll.flatMap((item) => item.theme);
		return _.uniq(values);
	});

	watch(themes, (value) => {
		const themesStore = useThemesStore();
		value.forEach((theme) => {
			themesStore.fetchById(theme);
		});
	});

	const filters = ref({section: [], theme: [], question: [], service: []});
	const setFilters = (v) => {
		const f = {section: [], theme: [], question: [], service: [], ...v};
		filters.value = f;
	};

	const resetFilters = () => {
		introduction.value = "";
		filters.value = {section: [], theme: [], question: [], service: []};
	};

	const start = async () => {
		startLoading();
		const config = useConfigStore();
		config.watchDoc("default");
		const questionsStore = useQuestionsStore();
		await questionsStore.fetchAll();
		endLoading();
	};

	const setIntro = (str) => {
		introduction.value = str || "";
	};

	const startLoading = () => {
		loading.value++;
	};

	const endLoading = () => {
		loading.value--;
	};

	// const getSource = ({source, value, organisation}) => {
	// 	if (source == "national") {
	// 		return nationalAverageStore.series[value];
	// 	}
	// 	if (source == "organisation") {
	// 		return organisationsStore.keyed[organisation][data];
	// 	}
	// 	if (source == "aggregate") {
	// 		return columnsStore.aggregateData[value];
	// 	}
	// };

	// const data = computed(() => {
	// 	let destination = {};
	// 	return columnsStore.visibleScoredColumns.map((column) => {
	// 		let source = getSource(column);
	// 		return rowsStore.mapSeries(
	// 			destination,
	// 			source,
	// 			column.value
	// 		);
	// 	});
	// });
	// const filteredData = [];
	const filteredData = computed(() => {
		let labels = columnsStore.visibleColumns.map((c) => c.value);
		let aggregateBy = columnsStore.dataLabelOptions.find( l => labels.includes(l) );
		return rowsStore.process(labels, filters.value, aggregateBy, false);
	});

	const autoClick = ref(null);

	return {
		autoClick,
		tab,
		years, 
		title,
		selection,
		selected,
		chartData,
		includedOrganisations,
		loading,
		startLoading,
		endLoading,
		start,
		filters,
		resetFilters,
		setFilters,
		filteredData,
		// filteredData2,
		introduction,
		setIntro,
		// data
	};
});
