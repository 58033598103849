<template>
	<v-btn block variant="text" dark @click="download">
		{{ buttonText }}
	</v-btn>
</template>

<script setup>
	import {useToastStore} from "@/stores/modules/toast";
	import {useDashboardStore} from "@/stores/modules/dashboard";
	import {useQuestionsStore} from "@/stores/modules/questions";
	import {useSectionsStore} from "@/stores/modules/sections";
	import {useServicesStore} from "@/stores/modules/services";
	import {useThemesStore} from "@/stores/modules/themes";
	import {computed, defineProps, onMounted} from "vue";
	const props = defineProps({
		rows: {type: Array, default: () => []}, // format: {x: "Abc"}
		columns: {type: Array, default: () => []}, // format: [{value: "x", label: "ABC"}]
		fileName: {type: String, default: "file"},
		buttonText: {type: String, default: "Download CSV"}
	});

	const questionsStore = useQuestionsStore();
	const themesStore = useThemesStore();
	const sectionsStore = useSectionsStore();
	const dashboard = useDashboardStore();
	const servicesStore = useServicesStore();

	const rowsWithLabels = computed(() => {
		return props.rows.map((row) => {
			if (row.section) {
				row.section = sectionsStore.getLabel(row.section);
			}
			if (row.theme) {
				row.theme = themesStore.getLabel(row.theme);
			}
			if (row.question) {
				row.question = questionsStore.getLabel(row.question);
			}
			if (row.service) {
				row.service = servicesStore.getLabel(row.service);
			}
			return row;
		});
	});
	const download = () => {
		let res = [];
		res.push(props.columns.map((col) => col.title));
		rowsWithLabels.value.forEach((row) => {
			let d = [];
			props.columns.forEach((col) => {
				d.push(JSON.stringify(row[col.value]));
			});
			res.push(d);
		});
		let content = "data:text/csv;charset=utf-8,";
		res.forEach((arr) => {
			if (arr) {
				const row = arr.join(",");
				content += row + "\r\n";
			}
		});
		var encodedUri = encodeURI(content);
		var link = document.createElement("a");
		link.setAttribute("href", encodedUri);
		link.setAttribute("download", props.fileName + ".csv");
		document.body.appendChild(link); // Required for FF

		link.click();
	};

	onMounted( () => {
		if( dashboard.autoClick == "download" && dashboard.tab == "table" ){
			let toasts = useToastStore();
			toasts.show("Generating File");
			setTimeout( () => {		
				download();
			}, 4000)
		}
	})
</script>
