<template>
	<v-sheet>

		<time-series-intro></time-series-intro>
		<v-btn block class="mt-6 mb-6"  size="x-large" color="success" to="dashboard">
			<v-icon class="mr-5" size="small">mdi-auto-fix</v-icon>
			New! Data Assistant
		</v-btn>	
		<loading-spinner v-model="dashboard.loading"></loading-spinner>
		<time-series-sidebar></time-series-sidebar>
		<time-series-viz v-if="timeSeriesStore.datasets.length"></time-series-viz>
	</v-sheet>
</template>

<script setup>
	import {useDashboardStore} from "@/stores/modules/dashboard";
	import {useTimeSeriesStore} from "@/stores/modules/timeSeries";
	import TimeSeriesViz from "@c/time-series/TimeSeriesViz";
	import TimeSeriesSidebar from "@c/time-series/TimeSeriesSidebar";
	import TimeSeriesIntro from "@c/time-series/TimeSeriesIntro";
	import LoadingSpinner from "@c/ui/LoadingSpinner";
	import {onUnmounted} from "vue";
	const timeSeriesStore = useTimeSeriesStore();
	timeSeriesStore.start();
	const dashboard = useDashboardStore();

	onUnmounted(() => {
		timeSeriesStore.end();
	});
</script>
