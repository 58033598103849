<template>
	<v-sheet>
		<h1 class="font-weight-light">
			Scottish Government/COSLA Digital Maturity Assessment Data
		</h1>
		<v-expansion-panels v-model="openPanel" class="mb-5 mt-6" flat  bg-color="lightest">
			<v-expansion-panel>
				<v-expansion-panel-text class="pt-3">
					<p>
					This interactive dashboard provides access to data collected as part of the Digital Maturity Assessment, including (where available)
					</p>
					<ul class="ml-5">
						<li>
							2023 and 2024 assessment data for your organisation and your local partner
							organisations (scores and responses)
						</li>
						<li>
							2019 comparison data for mapped questions (scores and responses)
						</li>
						<li>The 2023 and 2024 national averages</li>
					</ul>
					<p>
						Data can be viewed in table format or as a visualisation (note that
						only numeric data can be visualised). You can switch between those
						views using the tabs below.
					</p>
					<p>
						You can select data you want to view using the controls on the right.
						For more information, please visit the tutorials page.
					</p>
				</v-expansion-panel-text>
				<v-expansion-panel-title>
					<b v-if="0 == openPanel">Close</b>
					<b v-else>About these results</b>
				</v-expansion-panel-title>
			</v-expansion-panel>
		</v-expansion-panels>
		<v-btn block class="mt-6 mb-6" color="success" size="x-large" if="!preVizOpen" @click="preVizOpen = true">
			<v-icon class="mr-5" size="small">mdi-auto-fix</v-icon>
			New! Data Assistant
		</v-btn>
		<template v-if="auth.isLoggedIn">
			<v-dialog v-model="preVizOpen" max-width="1100" persistent="">
				<pre-viz-questionnaire v-model="preVizOpen" @close="() => preVizOpen = false"></pre-viz-questionnaire>
			</v-dialog>
			<v-card>
				<v-tabs v-model="dashboard.tab" bg-color="primary">
					<v-tab value="table">Table View</v-tab>
					<v-tab value="viz">Visualisation</v-tab>
				</v-tabs>
				<v-card-text>
					<v-window v-model="dashboard.tab">
						<v-window-item value="table">
							<main-table></main-table>
						</v-window-item>
						<v-window-item value="viz">
							<main-viz></main-viz>
						</v-window-item>
					</v-window>
				</v-card-text>
			</v-card>
			<dashboard-sidebar></dashboard-sidebar>
			<loading-spinner v-if="!preVizOpen" v-model="dashboard.loading"></loading-spinner>
		</template>
		</v-sheet>
</template>

<script setup>
	import {computed, onMounted, ref, watch } from "vue";
	import { useAuthStore } from "@/stores/modules/auth";
	import { useDashboardStore } from "@/stores/modules/dashboard";
	import MainTable from "@/components/dashboard/MainTable";
	import MainViz from "@/components/dashboard/MainViz";
	import DashboardSidebar from "@/components/dashboard/DashboardSidebar";
	import LoadingSpinner from "@/components/ui/LoadingSpinner";
	import PreVizQuestionnaire from "@/components/pre-viz-questions/QuestionWrapper";

	const auth = useAuthStore();
	const dashboard = useDashboardStore();

	const openPanel = ref(null)
	const start = ref(false);
	const preVizOpen = ref(true);


	onMounted(() => {
		dashboard.start();
	});
</script>
